<template>
  <!-- 
  Properties:
    title
    showSearch
    tabList
    searchCriteria

  Methods:
    udpate-search
    select-tab

  Uses slot for content, and that will be either an IteratorDisplay or PageDisplay with its content determined by which tab is selected
 -->
  <page-sizer>
    <v-toolbar>
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Search"
        dense
        class="mt-8"
        v-if="showSearch"
      ></v-text-field>

      <template v-slot:extension>
        <v-tabs
          grow
          color="blue darken-4"
          v-model="section"
          centered
          slider-color="blue darken-4"
          slider-size="5"
          show-arrows
        >
          <v-tab
            v-for="(name, sectionIndex) in tabList"
            :key="`section-${sectionIndex}`"
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <slot class="mt-1"></slot>
  </page-sizer>
</template>

<script>
import PageSizer from "../PageSizer.vue";
export default {
  props: ["title", "showSearch", "tabList", "searchCriteria", "selectedTab"],
  components: { PageSizer },
  computed: {
    search: {
      get() {
        return this.searchCriteria;
      },
      set(value) {
        this.$emit("update-search", value);
      },
    },

    section: {
      get() {
        return this.selectedTab;
      },
      set(value) {
        this.$emit("select-tab", value);
      },
    },
  },
};
</script>
